import React, {useEffect, useState} from 'react';

import './cooperation.css'
import CooperationTable from "./CooperationTable";
import CooperationPagination from "./CooperationPagination";
import axios from "axios";
import {ip} from "../../../../ip";
import {storage} from "../../../../services";

const Cooperation = () => {

    const token = storage.local.get("token");
    const user = storage.local.get("user");

    const [tableData, setTableData] = useState([
        {name: "salom", age: "To'landi", address: "refrfr", pay: true, key: 1},
        {name: "0000", age: "TO'LANMADI", pay: false, key: 2},
        {name: "salom"},
        {name: "salom"},
        {name: "salom"},
        {name: "salom"},
        {name: "salom"},
    ]);

    const [cooperationTotal, setcooperationTotal] = useState(tableData.length);
    const [cooperationPaginationLimit, setcooperationPaginationLimit] = useState(10);
    const [cooperationPaginationCurrent, setcooperationPaginationCurrent] = useState(1);

    const cooperationPaginationOnchange = (e = 1, option) => {
        setcooperationPaginationCurrent(e)
        setcooperationPaginationLimit(option)
    }

    useEffect(()=>{
        axios.get(`${ip}/api/referral/getClickers`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                console.log(res)
            })
            .catch((error)=>{

            })
    },[])


    return (
        <div className="cooperation">
            <div className="cooperation_header">
                <div className="cooperation_header_body">
                    <div className="cooperation_header_inner">
                        <h2>MIJOZLAR</h2>
                        <div className="cooperation_header_inner_t">
                            <p>
                                Referal linkingiz orqali platformaga obuna bo’lgan mijozlardan 100 000 so’m miqdorda bonus
                                oling
                                !

                                Siz tomondan kelgan mijoz platformaga obunani xarid qilganidan so’ng bonusingiz ichki
                                hisobingizga tushadi. Bonus miqdoringiz 400 000 so’m bo’lgandan keyingina uni kartangizga
                                yechib
                                olsangiz bo’ladi !
                            </p>
                            <p>Referal linkingiz: https://myreg.click/ru/?partner_id=170005839</p>
                        </div>
                    </div>
                    <div className="cooperation_header_inner">
                        <h2>STATISTIKA</h2>
                        <div className="cooperation_header_inner_t">
                            <div className="cooperation_header_inner_table">
                                <div className="cooperation_header_inner_table_inner">
                                    <p>Kliklar</p><p>1500</p>
                                </div>
                                <div className="cooperation_header_inner_table_line"></div>
                                <div className="cooperation_header_inner_table_inner">
                                    <p>Ro’yxatdan o’tildi</p><p>1500</p>
                                </div>
                                <div className="cooperation_header_inner_table_line"></div>
                                <div className="cooperation_header_inner_table_inner">
                                    <p>Xarid qilindi</p><p>1500</p>
                                </div>
                                <div className="cooperation_header_inner_table_line"></div>
                                <div className="cooperation_header_inner_table_inner">
                                    <p>Konversiya</p><p>15%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cooperation_body">
                <div className="cooperation_body_table">
                    {/*<CooperationTable*/}
                    {/*    data={tableData}*/}
                    {/*/>*/}
                    {/*<div className="cooperation_body_pagination">*/}
                    {/*    <CooperationPagination*/}
                    {/*        cooperationPaginationLimit={cooperationPaginationLimit}*/}
                    {/*        cooperationPaginationCurrent={cooperationPaginationCurrent}*/}
                    {/*        cooperationPaginationOnchange={cooperationPaginationOnchange}*/}
                    {/*        cooperationTotal={cooperationTotal}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
                {/*<div className="cooperation_body_footer">*/}
                {/*    <div className="cooperation_body_footer_left">*/}
                {/*        <p>BALANS</p>*/}
                {/*        <div className="cooperation_body_footer_left_balans">100000 so'm</div>*/}
                {/*    </div>*/}
                {/*    <div className="cooperation_body_footer_right">*/}
                {/*        <div className="cooperation_body_footer_right_inner">HISOBNI YECHISH</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default Cooperation;