import './App.css';
import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";

import LandingPage from "./components/landingPage/LandingPage";
import Dashboard from "./components/user/Dashboard";
import Layout from './components/user/Layout';

import {getMeAction, LOGIN_REQUEST, LoginFailure, loginResponse} from "./redux/action/action";
import {api, storage} from "./services";
import {Navigate, Route, Routes , BrowserRouter} from "react-router-dom";
import Login from "./components/loginPage/Login";
import Registration from "./components/loginPage/Registration";
import {ip} from "./ip";
import axios from "axios";
import Loader from "./components/loading/Loader";


function App() {

    const dispatch = useDispatch();
    const {isAuthenticated , isFetched} = useSelector(store => store.auth);
    const token = storage.local.get("token");

    useEffect(() => {
        const loadFetch = async () => {
            dispatch(LOGIN_REQUEST());
            try {
                const {data} = await api.get(`${ip}/api/v1/auth/getMe`)
                console.log(data)
                const user = data?.item;
                if (user){
                    dispatch(getMeAction(data?.item));
                }else {
                    dispatch(LoginFailure());
                }
                // console.log(user)
            } catch (error) {
                dispatch(LoginFailure());
            } finally {
                dispatch(loginResponse());
            }
        };
        if (token) {
            loadFetch();
        }
    }, [dispatch]);


    if (!isFetched){
        return <Loader/>
    }


    return (
        <React.Fragment>
            {isAuthenticated ?
                <Layout/>
                :
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<LandingPage/>}/>
                        <Route path='/login' element={<Login/>}/>
                        <Route path='/register' element={<Registration/>}/>
                        <Route path='*' element={<Navigate to="/"/>}/>
                    </Routes>
                </BrowserRouter>
            }
        </React.Fragment>
    );
}

export default App;
