import React from 'react';


import "./notFound.css";

const NotFound = () => {
    return (
        <div className="not_found">
            <div className="not_found_inner">
                <h1>404</h1>
                {/*<p>In Progress</p>*/}
                <p>Loyiha jarayonda...</p>
                {/*<p>Kechirasiz, siz tashrif buyurgan sahifa hali mavjud emas.</p>*/}
            </div>
        </div>
    );
};

export default NotFound;