import React from 'react';

import {Collapse} from "antd";

import './module.css';
import {Link} from "react-router-dom";
const Module = () => {
    return (
        <div className="module">
            <div className="module_header">
                <h2>MODUL VA DARSLIKLAR</h2>
            </div>
            <div className="module_body">
              <div className="module_body_inner">
                  <div className="module_body_inner_bloc_inner2">
                      <div className="module_body_inner_bloc2_inner2_inner">
                          <h2>FACEBOOK ADS</h2>
                          <Collapse
                              items={[
                                  {
                                      key: '1',
                                      label: 'KIRISH. TARGETING NIMA?',
                                      children:
                                          <ul>
                                              <li>Darsliklarni qanday o’zlashtirish kerak?</li>
                                              <li>Targeting nima? U qanday ishlaydi?</li>
                                          </ul>
                                  },
                              ]}
                          />
                          <Collapse
                              items={[
                                  {
                                      key: '1',
                                      label: 'INSTAGRAM VA FACEBOOK',
                                      children:
                                          <ul>
                                              <li>Darsliklarni qanday o’zlashtirish kerak?</li>
                                              <li>Targeting nima? U qanday ishlaydi?</li>
                                          </ul>
                                  },
                              ]}
                          />
                          <Collapse
                              items={[
                                  {
                                      key: '1',
                                      label: '1 - MODUL. STRUKTURA | ADS VA BM',
                                      children:
                                          <ul>
                                              <li>Darsliklarni qanday o’zlashtirish kerak?</li>
                                              <li>Targeting nima? U qanday ishlaydi?</li>
                                          </ul>
                                  },
                              ]}
                          />
                          <Collapse
                              items={[
                                  {
                                      key: '1',
                                      label: '2 - MODUL. BUSINESS MANAGER',
                                      children:
                                          <ul>
                                              <li>Darsliklarni qanday o’zlashtirish kerak?</li>
                                              <li>Targeting nima? U qanday ishlaydi?</li>
                                          </ul>
                                  },
                              ]}
                          />
                          <Collapse
                              items={[
                                  {
                                      key: '1',
                                      label: '3 - MODUL. ADS MANAGER',
                                      children:
                                          <ul>
                                              <li>Darsliklarni qanday o’zlashtirish kerak?</li>
                                              <li>Targeting nima? U qanday ishlaydi?</li>
                                          </ul>
                                  },
                              ]}
                          />
                      </div>
                      <div className="module_body_inner_bloc2_inner2_inner">
                          <div className="module_body_inner_bloc2_inner2_inner_video">

                          </div>
                      </div>
                  </div>
                  <div className="module_body_button">
                      <Link to="/user/all-courses"><button>BEKOR QILISH</button></Link>
                      <button>XARID QILISH</button>
                  </div>
              </div>
            </div>
        </div>
    );
};

export default Module;