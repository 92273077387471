import React from 'react';
import { Space, Table, Tag } from 'antd';


import fileIcon from '../../../../images/user/userDashboard/fileicon.png';
import './paymentHistory.css';


import styled from 'styled-components';
import {ip} from "../../../../ip";
export const TableStyles = styled(Table)`
  tbody {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  thead tr th {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${({ theme }) => theme.trhover};
  }
`;

const PaymentHistoryTable = (props) => {

    const {
        data,
    } = props;


    const columns = [
        {
            title: 'SANA',
            dataIndex: 'name',
            key: 'name',
            textAlign : 'center',
            render: (text , record) => <div className="payment_history_body_table_text">{record?.payTime}</div>,
        },
        {
            title: 'HOLAT',
            dataIndex: 'age',
            key: 'age',
            textAlign : 'center',
            render: (text , record) => <div className={record.status == "PAYED" ? "payment_history_body_table_text payment_history_body_table_text_pay" :
            "payment_history_body_table_text payment_history_body_table_text_no_pay"}>{record.status == "PAYED" ? "To'langan" : "Bekor qilingan"}</div>,
        },
        {
            title: 'CHEK',
            dataIndex: 'address',
            key: 'address',
            textAlign : 'center',
            render: (text , record) => <div className="payment_history_body_table_text">
                <a href={`${ip}/api/v1/downloadCheck?transactionId=${record.id}`}><img src={fileIcon}/></a>
            </div>,
        },
        {
            title: 'SUMMA',
            dataIndex: 'SUMMA',
            key: 'address',
            textAlign : 'center',
            render: (text , record) => <div className="payment_history_body_table_text">{record?.amount/100}</div>,
        },
        // {
        //     title: 'KURS',
        //     dataIndex: 'KURS',
        //     key: 'address',
        //     textAlign : 'center',
        //     render: (text) => <div className="payment_history_body_table_text">{text}</div>,
        // },
        {
            title: 'KARTA RAQAM',
            dataIndex: 'KARTA RAQAM',
            key: 'address',
            textAlign : 'center',
            render: (text , record) => <div className="payment_history_body_table_text">{record?.cardNumber}</div>,
        },

    ];

    return (
        <div>
            <TableStyles
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </div>
    );
};

export default PaymentHistoryTable;