import React, {useState} from 'react';
import {Layout, Menu} from 'antd';
import {BrowserRouter, Link} from 'react-router-dom';
import useWindowDimensions from '../../hooks/hooks';

import './dashboard.css';

import logo from '../../images/user/icon copy (3) 2.png';
import headerSetting from '../../images/user/Vector (38).png';
import smile from '../../images/user/🦆 icon _user smile line_.png';
import dashboard from '../../images/user/Vector (39).png';
import videoIcon from '../../images/user/Vector (40).png';
import history from '../../images/user/Vector (41).png';
import links from '../../images/user/Vector (42).png';
import files from '../../images/user/Vector (43).png';
import books from '../../images/user/Vector (44).png';
import sertificat from '../../images/user/Vector (45).png';
import hamkorlik from '../../images/user/Vector (46).png';
import settings from '../../images/user/Vector (47).png';
import question from '../../images/user/Group 14290.png';
import RootPage from "../../pages/root";
import {LoginFailure, LoginSuccess} from "../../redux/action/action";
import {useDispatch} from "react-redux";
import {Button, Popconfirm, message} from 'antd';

import {ActionType} from "../../redux/constants";
import {storage} from "../../services";

const {Header, Sider, Content} = Layout;


const Dashboard = () => {
    const token = storage.local.get("token");
    const user = storage.local.get("user");

    const dispatch = useDispatch();
    const {width} = useWindowDimensions();
    const sidebarWidth = width < 1370 ? 90 : 100;
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKey, setSelectedKey] = useState(["2"]);
    const [openKeys, setOpenKeys] = React.useState(['sub1']);
    const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8', 'sub9',];


    const handleClickListItem = (title, id) => {
        // setCheckedItemTitle(title)
        // dispatch(isRefresh(id));
        localStorage.setItem('selected-id', JSON.stringify([`${id}`]));
        setSelectedKey([`${id}`]);
    }

    const selectKey = JSON.parse(localStorage.getItem('selected-id'));
    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
            localStorage.setItem('keys', JSON.stringify(keys));
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
            localStorage.setItem('keys', JSON.stringify(latestOpenKey ? [latestOpenKey] : []));
        }
    };
    const openKeysLocal = JSON.parse(localStorage.getItem('keys'));

    const logOut = () => {
        dispatch(LoginFailure(''));
        window.location.href = '/';
    }
    const confirm = (e) => {
        logOut();
    };

    return (
        <BrowserRouter>
            <Layout>
                <Sider
                    width={sidebarWidth}
                    theme={'light'}
                    className={`left_sider_menu`}
                    trigger={null}
                    collapsible
                    collapsed={false}
                >
                    <div className="logo">
                        <img src={logo}/>
                    </div>
                    <div>
                        <Menu
                            theme={'light'}
                            className={`siderBackColor_menu`}
                            onOpenChange={onOpenChange}
                            openKeys={openKeysLocal}
                            mode="inline"
                            defaultSelectedKeys={selectKey}
                            defaultOpenKeys={openKeysLocal}
                        >
                            <Menu.Item className={`saidbar_link`}
                                       onClick={() => handleClickListItem('Dashboard', 2)}
                                       key="2"
                            >
                                <Link to="/user/dashboard">
                                    <img src={dashboard}/>
                                    <p>DASHBOARD</p>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className={`saidbar_link`}
                                       onClick={() => handleClickListItem('Dashboard', 3)}
                                       key="3"
                            >
                                <Link to="/user/all-courses">
                                    <img src={videoIcon}/>
                                    <p>BARCHA<br/> KURSLAR</p>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className={`saidbar_link`}
                                       onClick={() => handleClickListItem('Dashboard', 4)}
                                       key="4"
                            >
                                <Link to="/user/payment-history">
                                    <img src={history}/>
                                    <p>TOLOV TARIXI</p>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className={`saidbar_link`}
                                       onClick={() => handleClickListItem('Dashboard', 5)}
                                       key="5"
                            >
                                <Link to="/user/links">
                                    <img src={links}/>
                                    <p>LINKLAR</p>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className={`saidbar_link`}
                                       onClick={() => handleClickListItem('Dashboard', 6)}
                                       key="6"
                            >
                                <Link to="/user/files">
                                    <img src={files}/>
                                    <p>FAYLLAR</p>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className={`saidbar_link`}
                                       onClick={() => handleClickListItem('Dashboard', 7)}
                                       key="7"
                            >
                                <Link to="/user/library">
                                    <img src={books}/>
                                    <p>KUTUBXONA</p>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className={`saidbar_link`}
                                       onClick={() => handleClickListItem('Dashboard', 8)}
                                       key="8"
                            >
                                <Link to="/user/certificate">
                                    <img src={sertificat}/>
                                    <p>SERTIFIKAT</p>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className={`saidbar_link`}
                                       onClick={() => handleClickListItem('Dashboard', 9)}
                                       key="9"
                            >
                                <Link to="/user/cooperation">
                                    <img src={hamkorlik}/>
                                    <p>HAMKORLIK</p>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className={`saidbar_link`}
                                       onClick={() => handleClickListItem('Dashboard', 10)}
                                       key="10"
                            >
                                <Link to="/user/courses">
                                    <img src={settings}/>
                                    <p>SOZLAMALAR</p>
                                </Link>
                            </Menu.Item>


                            <div className="question">
                                <img src={question}/>
                            </div>

                        </Menu>

                    </div>

                </Sider>

                <Layout className={`site-layout`}>
                    <Header className="header">
                        <div className="header_inner">
                            <div className="header_left">
                                <h1>SALOM {user?.fullName} !</h1>
                                <h2>KURSLARNI O’ZLASHTIRISHINGIZDA OMAD TILAB QOLAMIZ</h2>
                            </div>
                            <div className="header_right">
                                <div className="header_right_setting">
                                    <img src={headerSetting}/>
                                </div>

                                <Popconfirm
                                    title="Tizimdan chiqish"
                                    // description="Are you sure to delete this task?"
                                    onConfirm={confirm}
                                    cancelText={"Bekor qilish"}
                                    okText={"Chiqish"}
                                >
                                    <div className="header_right_name">
                                        <p>{user?.username}</p>
                                        <img src={smile}/>
                                    </div>
                                </Popconfirm>
                            </div>
                        </div>
                        <div className="header_line"></div>
                    </Header>

                    <Content>
                        <RootPage/>
                    </Content>
                </Layout>
            </Layout>
        </BrowserRouter>
    );
};

export default Dashboard;