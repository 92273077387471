import React, {useEffect, useState} from 'react';


import './files.css';
import {Collapse} from "antd";
import {storage} from "../../../../services";
import axios from "axios";
import {ip} from "../../../../ip";

const Files = () => {

    const token = storage.local.get("token");

    const [filesData , setFilesData] = useState([
        // {name:"salom" , sourceFiles : [{description : "desk"}]},
        // {name:"salom2" , sourceFiles : [{description : "desk2"}]},
        // {name:"salom3" , sourceFiles : [{description : "desk3"}]},
        // {name:"salom4" , sourceFiles : [{description : "desk4"}]},
    ]);

    useEffect(()=>{
        axios.get(`${ip}/api/v1/sourceFile/allTopic`,
            {headers:{'Authorization': `Bearer ${token}`}}
        )
            .then((res)=>{
                console.log(res)
                setFilesData(res?.data?.item);
            });
    },[])


    return (
        <div className="files">
            <div className="files_header">
                <h2>FOYDALI FAYLLAR</h2>
            </div>
            <div className="files_body">
               <div className="files_body_inner">
                   {filesData?.map((item , index)=>{
                       if (index%2==0){
                           return(
                               <Collapse
                                   items={[{
                                       key: index,
                                       label: item?.name,
                                       children:
                                           <ul>
                                               {item?.sourceFiles?.map((x , y)=>{
                                                   return(
                                                       <li key={y}>
                                                           <a href={item?.premium === true ? `${ip}/api/file/download/${x?.fileId}` : '#'}
                                                        style={{textDecoration : "none" , color: "white"}}>
                                                               {x?.description}
                                                       </a>
                                                       </li>
                                                   )
                                               })}
                                           </ul>
                                   },
                                   ]}
                               />
                           )
                       }
                   })}

               </div>
               <div className="files_body_inner">
                   {filesData?.map((item , index)=>{
                       if (index%2==1){
                           return(
                               <Collapse
                                   items={[{
                                       key: index,
                                       label: item?.name,
                                       children:
                                           <ul>
                                               {item?.sourceFiles?.map((x , y)=>{
                                                   return(
                                                       <li key={y}>
                                                           <a href={item?.premium === true ? `${ip}/api/file/download/${x?.fileId}` : '#'}
                                                              style={{textDecoration : "none" , color: "white"}}>{x?.description}
                                                       </a>
                                                       </li>
                                                   )
                                               })}
                                           </ul>
                                   },
                                   ]}
                               />
                           )
                       }
                   })}
               </div>
            </div>
        </div>
    );
};

export default Files;