import React, {useState} from 'react';
import {jsPDF} from "jspdf";
import html2canvas from 'html2canvas';

import './certificatePDF.css';
import globe from './img/globeorig.png';
import {storage} from "../../../../../services";
import QRCode from "qrcode.react";

const CertificatePDF = (props) => {
    const {
        certificateInfo,
        qrCodeLink
    } = props;
    const token = storage.local.get("token");
    const user = storage.local.get("user");

    const date = new Date();
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;

    const params = new URLSearchParams({
        userIdBase64: certificateInfo?.userIdBase64,
        certificateIdBase64: certificateInfo?.certificateIdBase64,
        fullName : certificateInfo?.fullName,
        certificateName : certificateInfo?.certificateName
    }).toString();

    return (
        <div className="certificate_pdf">
            <div className="certificate_pdf_body" id="pdf-content">
                {/*<div className="certificate_pdf_body_inner_bio_inner2">*/}
                {/*    <img src={globe}/>*/}
                {/*</div>*/}
                <div className="certificate_pdf_body_inner">
                    <div className="certificate_pdf_body_inner_text">
                        <div className="certificate_pdf_body_inner_text_inner">
                            <div className="certificate_pdf_body_inner_text_inner_pro">PRO</div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                        </div>
                    </div>
                    <div className="certificate_pdf_body_inner_text2">
                        <div className="certificate_pdf_body_inner_text_inner2">
                            <div className="certificate_pdf_body_inner_text_inner_pro2">DIGITAL</div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                            <div className="certificate_pdf_body_inner_text_inner_column"></div>
                        </div>
                    </div>
                    <div className="certificate_pdf_body_inner_title">
                        <p>FOR PROFESSIONALS WITH GLOBAL INTENTION</p>
                    </div>
                    <div className="certificate_pdf_body_inner_name">
                        <div className="certificate_pdf_body_inner_name_title">
                            <div className="certificate_pdf_body_inner_name_title_inner">
                                <p>CERTIFICATE OF SPECIALIZATION</p>
                            </div>
                        </div>
                        <div className="certificate_pdf_body_inner_name_user">
                            <p>{user?.fullName}</p>
                        </div>
                    </div>
                    <div className="certificate_pdf_body_inner_bio">
                        <div className="certificate_pdf_body_inner_bio_inner">
                            <div className="certificate_pdf_body_inner_bio_title">
                                <p>CONGRATULATIONS!</p>
                            </div>
                            <div className="certificate_pdf_body_inner_bio_bio">
                                <p>
                                    This Certificate was awarded to <br/><span>{certificateInfo?.fullName}</span> for
                                    successfully
                                    completing the <span>{certificateInfo?.certificateName}</span> at PRO DIGITAL PLATFORM. We are sure
                                    that the knowledge you have gained in the
                                    course will be useful for your further Digital Marketing projects. We wish you
                                    success
                                    in your future work!
                                </p>
                            </div>
                            <div className="certificate_pdf_body_inner_bio_date">
                                <p>DATE : {formattedDate}</p>
                            </div>
                        </div>

                        <div className="certificate_pdf_body_inner_bio_inner2">
                            <img src={globe}/>
                        </div>

                    </div>

                    <div className="certificate_pdf_body_inner_footer">
                        <div className="certificate_pdf_body_inner_footer_text">
                            <p>LLC</p>
                            <p>REVX AGENCY</p>
                        </div>
                        <div className="certificate_pdf_body_inner_footer_qrCode">
                            <QRCode value={`https://prodigitalcertificatecheckuz.netlify.app?${params}`} renderAs="canvas"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CertificatePDF;
